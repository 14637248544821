<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">

              <b-form-input v-model="nameQuery" class="d-inline-block mr-1" placeholder="Name..."
                @keyup.enter="fetchFreelancers" />

              <b-form-input v-model="emailQuery" class="d-inline-block mr-1" placeholder="Email..."
                @keyup.enter="fetchFreelancers" />
              <b-button variant="secondary" @click="fetchFreelancers" class="mr-2">
                <span class="text-nowrap">Filter</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table ref="freelancersTable" class="position-relative" :items="freelancers" responsive :fields="tableColumns"
        show-empty empty-text="No matching records found" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">

        <!-- Column: Name -->
        <template #cell(fullName)="data">
          <b-link class="font-weight-bold d-block text-nowrap"
            :to="{ name: 'FreelancersShow', params: { professionalId: data.item.id } }">
            {{ data.item.fullName }}
          </b-link>
        </template>

        <!-- Column: Active -->
        <template #cell(isActive)="data">
          <b-avatar size="32" :variant="getActiveVariant(data.item.isActive)">
            <feather-icon :icon="getActiveIcon(data.item.isActive)" />
          </b-avatar>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item @click="toggleActive(data.item)">
              <feather-icon :icon="data.item.isActive ? 'XCircleIcon' : 'CheckCircleIcon'" />
              <span class="align-middle ml-50">{{ data.item.isActive ? 'Deactivate' : 'Activate' }}</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'FreelancersShow', params: { professionalId: data.item.id } }">
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Show</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalFreelancers" :per-page="perPage" first-number
              last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BPagination, BLink, BAvatar, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BAvatar,
    BBadge,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormCheckbox
  },

  data() {
    return {
      currentPage: 1,
      perPage: 100,
      perPageOptions: [10, 25, 50, 100],
      nameQuery: '',
      emailQuery: '',
      tableColumns: [
        {
          key: 'fullName',
          label: 'Name',
          sortable: true
        },
        {
          key: 'emailAddress',
          label: 'Email',
          sortable: true
        },
        {
          key: 'status',
          sortable: true
        },
        {
          key: 'isActive',
          sortable: true
        },
        {
          key: 'accountCreatedOn',
          label: 'Created At',
          sortable: true
        },
        { key: 'actions' },
      ],
      sortBy: 'fullName',
      sortDesc: false,
      freelancers: [],
      totalFreelancers: 0
    };
  },

  computed: {
    dataMeta() {
      return {
        from: this.perPage * (this.currentPage - 1) + (this.freelancers.length ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + this.freelancers.length,
        of: this.totalFreelancers,
      }
    },

    queryParams() {
      return {
        sortBy: this.sortBy,
        sortOrder: this.sortDesc ? 1 : 0,
        skip: (this.currentPage - 1) * this.perPage,
        take: this.perPage,
      }
    },
  },

  watch: {
    queryParams: {
      handler() {
        this.fetchFreelancers();
      },
      immediate: true
    }
  },

  methods: {
    async fetchFreelancers() {
      const params = this.queryParams;
      params.name = this.nameQuery;
      params.email = this.emailQuery;

      const response = await this.$http.get('professionals', {
        params
      });
      this.freelancers = response.data.results ?? [];
      this.totalFreelancers = response.data.count;
    },

    getActiveVariant(active) {
      return active ? 'light-success' : 'light-danger';
    },

    getActiveIcon(active) {
      return active ? 'CheckCircleIcon' : 'XIcon';
    },

    async toggleActive(user) {
      const path = `freelancers/${user.id}/${user.isActive ? 'deactivate' : 'activate'}`;
      await this.$http.post(path);
      const successMessage = `${user.fullName} has been ${user.isActive ? 'deactivated' : 'activated'}`;

      this.$toast({
        component: ToastificationContent,
        props: {
          title: successMessage,
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });

      this.fetchFreelancers();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
